import React from 'react'
import { RegistryDescriptor } from "../../../../app/types";
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
import { ApplicationUnit } from '../../../regs';

const VatForm = React.lazy(() => import('./VatForm'))

class VatsRD extends RegistryDescriptor {
    gridEndpoint = 'vats'
    docEndpoint = 'vats'
    selector = {
        endpoint: 'vats',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        getOptionLabel: (option: SelectorListItem) => option.caption,
        queryParams: {_orderBy: 'code'},
    }
    name: ApplicationUnit = 'vats'
    columns =  [
        {name: 'code'},
        {name: 'name'},
        {name: 'memo'}
    ]
    defaultDocument = {status: 0}
    getDetailForm = (docPath: string) => <VatForm docPath={docPath} />
}

export const vats = new VatsRD()
