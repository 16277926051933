import React from "react";
import { ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";

const Form = React.lazy(() => import('./VatReportForm'));

const paramTypes : ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'}
};

class VatRD extends ReportDescriptor {

    reportEndpoint: string = 'vat_report';
    name: ApplicationUnit = 'vat_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params), _orderBy: 'vat_name'};
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./VatReportPrintForm');
}

export const vat_report = new VatRD();