import React from 'react';

import BaseTextEdit from './BaseTextEdit';
import { EditFlavor, TextAlignOption, TextEditType } from '../../../app/types';

interface TextEditProps {
    docPath: string
    field: string
    label?: string
    flavor?: EditFlavor
    type?: TextEditType
    multiline?: boolean
    autoFocus?: boolean
    textAlign?: TextAlignOption
}

export default function TextEdit (params: TextEditProps) {
    return <BaseTextEdit {...params} fullWidth={true} />
}