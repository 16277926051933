import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { getLocale, languages } from "../../i18n/messages";
import { Box, Button, List, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { setLocalSetupItem } from "../../redux/localSetup/localSetupSlice";
import { SETUP_LOCALE } from "../../app/const";
import { FormattedMessage } from "react-intl";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';

export const LanguageList = () => {

    const dispatch = useAppDispatch();
    const locale = getLocale()

    return (
        <Toolbar
            sx={{ justifyContent: 'center' }}
        >
            {languages.map((language) => (
                <Button
                    // disabled={locale === language}
                    startIcon={locale === language ? <CheckIcon /> : null}
                    size="small"
                    variant='outlined'
                    key={language}
                    onClick={() => { dispatch(setLocalSetupItem({ k: SETUP_LOCALE, v: language })) }}
                    sx={{
                        fontSize: '0.7rem',
                        margin: '0 2px',
                        padding: '0.01rem 0.01rem',
                        // backgroundColor: locale === language ? 'orange' : 'transparent', 
                    }}
                >{language}</Button>
            ))}
        </Toolbar>
    );
};

export const LanguageSelectorMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch();
    const locale = getLocale();

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        language: string,
    ) => {
        dispatch(setLocalSetupItem({ k: SETUP_LOCALE, v: language }))
        setAnchorEl(null);
    };


    return (
        <Box sx={{ p: 1 }}>
            <Tooltip title={<FormattedMessage id='hint_language' defaultMessage='Language' />}>
                <Button
                    id="language-selector"
                    aria-haspopup="listbox"
                    aria-controls="language-menu"
                    aria-label="select language"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                    color="inherit"
                    sx={{ textTransform: 'none'}}
                    endIcon={<KeyboardArrowDownIcon />}
                    startIcon={<LanguageIcon />}
                >
                    <FormattedMessage id={'lang_' + locale} />
                </Button>
            </Tooltip>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'language-selector',
                    role: 'listbox',
                }}
            >
                {languages.map((language) => (
                    <MenuItem
                        key={language}
                        selected={language === locale}
                        onClick={(event) => handleMenuItemClick(event, language)}
                    >
                        <FormattedMessage id={'lang_' + language} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}