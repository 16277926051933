import React from 'react';

import { SimpleCodedNamedWithMemoRD } from '../../../../app/types/SimpleCodedNamedWithMemoRD';
const EditForm = React.lazy(() => import('./CompanyGroupEdit'))

class CompanyGroupsRD extends SimpleCodedNamedWithMemoRD {
    // name = 'companygroups'
    getDetailForm = (docPath: string) => <EditForm docPath={docPath} />
}

export const companygroups = new CompanyGroupsRD('companygroups')