import { warehouses } from './registries/warehouses/warehouses'
import { wh_inits } from './registries/wh_inits/wh_inits'
import { wh_incomes } from './registries/wh_incomes/wh_incomes'
import { wh_outcomes } from './registries/wh_outcomes/wh_outcomes'
import { wh_intra } from './registries/wh_intra/wh_intra'
import { wh_writeoffs } from './registries/wh_writeoffs/wh_writeoffs'
import { wh_state_report } from './reports/wh_state_report/wh_state_report'

export const warehouse_regs = { warehouses, wh_inits, wh_incomes, wh_outcomes, wh_intra, wh_writeoffs, wh_state_report };

export type WarehouseApplicationUnit = 'warehouses' 
    | 'wh_inits' | 'wh_incomes' | 'wh_outcomes' | 'wh_intra' | 'wh_writeoffs' 
    | 'wh_state_report';
