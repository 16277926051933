export const firebaseConfig = {
    apiKey: "AIzaSyAvfHQSgTEzXOuc-WAgK4irkHrOTaBdwVE",
    authDomain: "profit-web-v2.firebaseapp.com",
    projectId: "profit-web-v2",
    storageBucket: "profit-web-v2.appspot.com",
    messagingSenderId: "380574446578",
    appId: "1:380574446578:web:5cd423598926d76b9eb16b",
    measurementId: "G-N3LW1P1V07"
};


/*
export const firebaseConfig = {
    apiKey: "AIzaSyBP7eNU70zW_FqLdQppFrp4j6W4UCVBG5o",
    authDomain: "profit-web-6ed54.firebaseapp.com",
    projectId: "profit-web-6ed54",
    storageBucket: "profit-web-6ed54.appspot.com",
    messagingSenderId: "688770775120",
    appId: "1:688770775120:web:02ecd37e5e4d57642eb955",
    measurementId: "G-K7JLXC2CHS"
}

 */