import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { getApplicationUnitDescriptor } from '../../profit';
import { ApplicationUnit } from '../../profit/regs';
import { postFile } from '../../profit/api';
import { ReportDataDescriptor } from '../../app/types/ApplicationUnitDescriptor';

export type ReportState = {
    regName: string
    params?: any
    data?: ReportDataDescriptor
    status: 'invalid' | 'loading' | 'idle' | 'error'
    error?: string
    pdfUrl?: string
    email_id?: number
}

const initialState: ReportState = {
    regName: '',
    params: undefined,
    data: undefined,
    status: 'invalid'
}

export interface LoadReportDataPayload {
    regName: string
    params?: any
}

export const loadReportData = createAsyncThunk(
    'reports/loadReportData',
    async (payload: LoadReportDataPayload, thunkApi) => {
        const parts = payload.regName.split('/');
        const reg: ApplicationUnit = (parts[1] === 'report' ? parts[2] : parts[1]) as ApplicationUnit;
        // console.log('loadReportData for', payload);
        const rd = getApplicationUnitDescriptor(reg);
        return await rd.loadReportData(payload.params);
    }
);

export const emailReport = createAsyncThunk(
    'reports/emailReport',
    async (payload: string, thunkApi) => {
        const parts = payload.split('/');
        const reg: ApplicationUnit = (parts[1] === 'report' ? parts[2] : parts[1]) as ApplicationUnit;
        console.log('emailReport for', payload);
        const rd = getApplicationUnitDescriptor(reg);

        const state = thunkApi.getState() as RootState;

        // Fetch the data from the blob URL
        const response = await fetch(state.reports.pdfUrl!);
        // Check if the fetch was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the blob data
        const blob = await response.blob();

        // Create a new File object from the blob
        const file = new File([blob], "filename", { type: blob.type });

        const url = parts[0] + '/create_email/'
            + '?recipient=' + (state.reports.data && state.reports.data.email_to || '')
            + '&subject=' + (state.reports.data && state.reports.data.report_title || '')
            + '&doc_type=' + reg
            + '&doc_id=' + parts[2];

        console.log('Posting file to ' + url + ' ...');

        const ret = await postFile(state, url, file);

        console.log('ret', ret);

        return ret.data;
        // return await rd.emailReport(payload.params);
    }
);

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        resetReport: (state, action) => {
            console.log('resetReport ' + action)
            state.status = 'invalid'
            state.data = undefined
            state.params = undefined
            state.regName = ''
            state.pdfUrl = undefined
            state.email_id = undefined
        },
        setPdfUrl: (state, action) => {
            state.pdfUrl = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadReportData.pending, (state, action) => {
                state.params = action.meta.arg.params
                state.regName = action.meta.arg.regName
                state.status = 'loading'
                state.data = undefined
                state.email_id = undefined
            })
            .addCase(loadReportData.fulfilled, (state, action) => {
                state.status = 'idle'
                state.data = action.payload
                state.email_id = undefined
            })
            .addCase(loadReportData.rejected, (state, action) => {
                console.log('rejected', action)
                state.status = 'error'
                state.error = action.error.message
                state.email_id = undefined
            })
            .addCase(emailReport.pending, (state, action) => {
                // state.params = action.meta.arg.params
                // state.regName = action.meta.arg;
                state.status = 'loading'
                state.email_id = undefined
                // state.data = undefined
            })
            .addCase(emailReport.fulfilled, (state, action) => {
                console.log('emailReport fulfilled', action.payload);
                state.status = 'idle';
                state.email_id = action.payload.email_id;
            })
            .addCase(emailReport.rejected, (state, action) => {
                console.log('rejected', action)
                state.status = 'error'
                state.error = action.error.message
                state.email_id = undefined
            })
    }
});

export const { resetReport, setPdfUrl } = reportsSlice.actions;

export const selectReport = (state: RootState) => state;
export const selectRegName = (state: RootState) => state.reports.regName;
export const selectParams = (state: RootState) => state.reports.params;
export const selectReportData = (state: RootState) => state.reports.data;
export const selectReportStatus = (state: RootState) => state.reports.status;
export const selectReportError = (state: RootState) => state.reports.error;
export const selectReportPdfUrl = (state: RootState) => state.reports.pdfUrl;
export const selectReportEmailId = (state: RootState) => state.reports.email_id;

export default reportsSlice.reducer;
