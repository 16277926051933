import React from 'react'
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
import { SimpleCodedNamedWithMemoRD } from '../../../../app/types/SimpleCodedNamedWithMemoRD';
const PricelistEdit = React.lazy(() => import('./PricelistEdit'))

class PricelistsRD extends SimpleCodedNamedWithMemoRD {

    defaultDocument = {status: 0}
    
    getDetailForm = (docPath: string) =>  <PricelistEdit docPath={docPath} />

    constructor() {
        super('pricelists')
        this.selector.getOptionLabel = (option: SelectorListItem) => option.caption
    }
}

export const pricelists = new PricelistsRD()