import React from "react";
import TextEdit from "../../../../ui/components/edit/TextEdit";
import { DocPathParams } from "../../../../app/types";
import ComboEdit from "../../../../ui/components/edit/ComboEdit";
import DateEdit from "../../../../ui/components/edit/DateEdit";

export default function WhInitsFilterForm(params: DocPathParams) {
    return <>
        <DateEdit {...params} field='doc_date_from' />
        <DateEdit {...params} field='doc_date_until' />
        <TextEdit {...params} field='total_from' type="number" />
        <TextEdit {...params} field='total_until' type="number" />
        <ComboEdit {...params} field='dst_wh_id' reg='warehouses' />
        <ComboEdit {...params} field='article_id' reg='articles' />
        {/* <ComboEdit {...params} field='object_id' reg='objects' />
        <ComboEdit {...params} field='currency_id' reg='currencies' /> */}
        {/* TODO creator <ComboEdit {...params} field='buyer_id' reg='companies' /> */}
        {/* TODO label <ComboEdit {...params} field='buyer_id' reg='companies' /> */}
    </>
}