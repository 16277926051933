import React from "react";
import { RegistryDescriptor } from "../../../../app/types";
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
import * as Yup from 'yup';
import { ApplicationUnit } from "../../../regs";

const CurrencyEdit = React.lazy(() => import('./CurrencyEdit'))

class CurrenciesRD extends RegistryDescriptor {
    gridEndpoint = 'currencies'
    docEndpoint = 'currencies'
    selector = {
        endpoint: 'currencies',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        getOptionLabel: (option: SelectorListItem) => option.caption,
        queryParams: {_orderBy: 'code'},
    }
    name: ApplicationUnit = 'currencies'
    columns =  [
        {name: 'code'},
        {name: 'name'},
        {name: 'rate'},
        {name: 'drate'}
    ]
    defaultDocument = {status: 0}
    getDetailForm = (docPath: string) => <CurrencyEdit docPath={docPath}/>
    getTitle = (doc: any): string  => {
        if(!!doc && doc.name)
            return doc.name
        else
            return ''
    }

    public getValidationSchema(): Yup.ObjectSchema<any> {
        return Yup.object().shape({
            code: Yup.string().required('Required').max(3).min(3).uppercase(),
            name: Yup.string().required('Required').max(50),
            rate: Yup.number().required('Required'),
            drate: Yup.number().required('Required'),
        });
    }
}

export const currencies = new CurrenciesRD()
