import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../redux/auth/authSlice";

const PrivateRoutes = () => {
    const user = useAppSelector(selectUser);
    return user ? <Outlet/> : <Navigate to="/login"/>
}

export default PrivateRoutes;