import { articlegroups } from "./registries/articlegroups/articlegroups";
import { articles } from "./registries/articles/articles";
import { banks } from "./registries/banks/banks";
import { companies } from "./registries/companies/companies";
import { countries } from "./registries/countries/countries";
import { currencies } from "./registries/currencies/currencies";
import { objects } from "./registries/objects/objects";
import { units } from "./registries/units/units";
import { vats } from "./registries/vats/vats";
import { companygroups } from "./registries/companygroups/companygroups";
import { emails } from "./registries/emails/emails";
import { paymenttypes } from "./registries/paymenttypes/paymenttypes";

export const common_regs = { articlegroups, articles, banks, companies, companygroups, countries,
    currencies, objects, units, vats, emails, paymenttypes };

export type CommonApplicationUnit = 'articlegroups'  | 'articles' | 'banks' | 'companies'
    | 'companygroups' | 'countries' | 'currencies' | 'objects' | 'units' | 'vats' | 'emails'
    | 'paymenttypes'
    ;