import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const accounts_report_sort_orders = [
    {value: 'code', label: 'code'},
    {value: 'name,code', label: 'name_code'},
    {value: 'group,code', label: 'group_code_article_code'},
    {value: 'group,name,code', label: 'group_name_code'},
    {value: 'wh_location,code', label: 'wh_location_code'},
    {value: 'wh_location,name,code', label: 'wh_location_name_code'},
];

export const paramTypes: ParamTypesDescriptors = {
    'dt': {type: 'date'},
    'wh_id': {type: 'select', selectorRd: 'warehouses', title: 'wh_id'},
};
