import React from "react";
// import WarehouseEdit from './WarehouseEdit'
import { SimpleCodedNamedWithMemoRD } from "../../../../app/types/SimpleCodedNamedWithMemoRD"
const WarehouseEdit = React.lazy(() => import('./WarehouseEdit'))

class WarehousesRD extends SimpleCodedNamedWithMemoRD {

    defaultDocument = {status: 0}
    
    getDetailForm = (docPath: string) =>  <WarehouseEdit docPath={docPath} />

    constructor() {
        super('warehouses')
    }
}

export const warehouses = new WarehousesRD()
