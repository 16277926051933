import React from "react";
import {ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { purchases_report_sort_orders } from "./const";

const Form = React.lazy(() => import('./PurchasesReportForm'));

const paramTypes : ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'supplier_id': {type: 'select', selectorRd: 'companies', title: 'supplier'},
    'article_id': {type: 'select', selectorRd: 'articles', title: 'article'},
    'obj_id': {type: 'select', selectorRd: 'objects', title: 'obj_id'},
    'sort_order': {type: 'options', items: purchases_report_sort_orders, title: 'sort_order'}
}
class CaPurchasesReportRD extends ReportDescriptor {

    reportEndpoint: string = 'purchases_report';
    name: ApplicationUnit = 'purchases_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params), _orderBy: 'doc_date'};   //TODO: order selector
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./PurchasesReportPrint');
}

export const purchases_report = new CaPurchasesReportRD();