import * as React from 'react';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicOnlyRoutes from './routes/PublicOnlyRoutes';

import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./configs/firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";


import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import NoMatch from './ui/screens/NoMatch';
import { IntlProvider } from 'react-intl';
import { messages, getLocale } from './i18n/messages'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/et'
import { useSelector } from "react-redux";
import { saveUser, selectAuthStatus } from './redux/auth/authSlice';
import { useAppDispatch, useProfitColorScheme } from './app/hooks';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { selectLocalSetupValue } from './redux/localSetup/localSetupSlice';
import { LOCAL_SETUP_KEYS } from './app/const';
import { getTheme } from './ui/themes';
// import WaitBoxLinear from './ui/components/WaitBoxLinear';
import { APP_URLS } from './configs/appConfig';

const CompanyDashboard = React.lazy(() => import('./ui/screens/CompanyDashboard'));
const StorageDashboard = React.lazy(() => import('./ui/screens/StorageDashboard'));
const Register = React.lazy(() => import('./ui/screens/Register'));
const Frontpage = React.lazy(() => import('./ui/screens/Frontpage'));
const DocsRegistry = React.lazy(() => import('./ui/components/DocsRegistry'));
const DocEditor = React.lazy(() => import('./ui/components/DocEditor'));
const Login = React.lazy(() => import('./ui/screens/Login'));
const ReportPage = React.lazy(() => import('./ui/components/ReportPage'));
const ReportPreviewPdf = React.lazy(() => import('./ui/components/print/ReportPreviewPdf'));
const DocumentPreview = React.lazy(() => import('./ui/components/print/DocumentPreview'));
const SharedSetupScreen = React.lazy(() => import('./ui/screens/SetupScreen'));
const BillingDashboard = React.lazy(() => import('./ui/screens/BillingDashboard'));
const DocViewer = React.lazy(() => import('./ui/screens/DocViewer'));

export default function App() {

    const dispatch = useAppDispatch();
    const locale = getLocale();

    initializeApp(firebaseConfig);
    const auth = getAuth();
    const status = useSelector(selectAuthStatus);
    const densityName = selectLocalSetupValue(LOCAL_SETUP_KEYS.THEME_DENSITY_NAME);
    const usedColorSchemeName = useProfitColorScheme();

    React.useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // const token = await user.getIdToken(true)
                dispatch(saveUser({
                    photoURL: user.photoURL,
                    uid: user.uid
                }))
            } else
                dispatch(saveUser(undefined))
        });
    }, [auth, dispatch])

    if (status === 'loading')
        return null

    // TODO
    // You can provide a way better UX than this when your app throws errors 
    // by providing your own ErrorBoundary or errorElement prop on your route.

    const router = createBrowserRouter(
        createRoutesFromElements([
            <Route element={<PublicOnlyRoutes />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
            </Route>,
            <Route element={<PrivateRoutes />}>
                <Route path={APP_URLS.FRONTPAGE} element={<Frontpage />} />
                <Route path={APP_URLS.BILLING} element={<BillingDashboard />} />
                <Route path="/:dbName/" element={<CompanyDashboard />} />
                <Route path="/:dbName/storage/" element={<StorageDashboard />} />
                <Route path="/:dbName/:regName" element={<DocsRegistry />} />
                <Route path="/:dbName/:regName/:id" element={<DocEditor />} />
                <Route path="/:dbName/:regName/:id/print" element={<DocumentPreview />} />
                <Route path="/:dbName/:regName/:id/print/:uniqueId" element={<DocumentPreview />} />
                <Route path="/:dbName/setup/:page" element={<SharedSetupScreen />} />
                <Route path="/:dbName/report/:reportName" element={<ReportPage />} />
                <Route path="/:dbName/report/:reportName/preview/pdf" element={<ReportPreviewPdf />} />
            </Route>,
            <Route path="/" element={<DocViewer />} />,
            <Route path="/doc/:docName" element={<DocViewer />} />,
            <Route path="*" element={<NoMatch />} />
            ])
    );

    const cs = getTheme(usedColorSchemeName, densityName);

    return (<>
        <ThemeProvider theme={cs}>
            <CssBaseline />
            <IntlProvider locale={locale} messages={messages[locale]}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale='et'>
                    <React.Suspense>
                        <RouterProvider router={router} />
                    </React.Suspense>
                </LocalizationProvider>
            </IntlProvider>
        </ThemeProvider>
    </>);
}