import { Box, FormControl, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { colorSchemeNames, densityNames } from ".";
import { selectLocalSetupValue, setLocalSetupItem } from "../../redux/localSetup/localSetupSlice";
import { LOCAL_SETUP_KEYS } from "../../app/const";
import { useAppDispatch } from "../../app/hooks";

import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { isDevMode } from "../../configs/appConfig";

export const ColorSchemeSelector = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const colorSchemeName = selectLocalSetupValue(LOCAL_SETUP_KEYS.THEME_COLORSCHEME_NAME, colorSchemeNames[0])

    const handleColorSchemeChange = (event: SelectChangeEvent) => {
        console.log(event.target.value)
        dispatch(setLocalSetupItem({ k: LOCAL_SETUP_KEYS.THEME_COLORSCHEME_NAME, v: event.target.value }))
    }

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newColorScheme: string | null,
    ) => {
        dispatch(setLocalSetupItem({ k: LOCAL_SETUP_KEYS.THEME_COLORSCHEME_NAME, v: newColorScheme }))
    };

    // console.log('csn: ', colorSchemeName)



    return isDevMode ? <Box sx={{ p: 1 }}><FormControl>
        <ToggleButtonGroup
            size="small"
            value={colorSchemeName}
            exclusive
            onChange={handleChange}
            aria-label="color scheme"
            color='primary'
        >{
                colorSchemeNames.map(name => {

                    const msg = intl.formatMessage({ id: 'colorscheme_' + name })

                    return <Tooltip title={msg} key={name}>
                        <ToggleButton value={name} aria-label={name + ' color scheme'} sx={{ color: name === colorSchemeName ? 'yellow' : undefined }}>
                            {name === 'system' && <AutoAwesomeOutlinedIcon fontSize='small' />}
                            {name === 'light' && <LightModeOutlinedIcon fontSize='small' />}
                            {name === 'dark' && <DarkModeOutlinedIcon fontSize='small' />}
                        </ToggleButton>
                    </Tooltip>
                })
            }

        </ToggleButtonGroup>
    </FormControl>
    </Box> : null

}

export default function ThemeSelector() {

    const dispatch = useAppDispatch()
    const densityName = selectLocalSetupValue(LOCAL_SETUP_KEYS.THEME_DENSITY_NAME, densityNames[0])

    const handleDensityChange = (event: SelectChangeEvent) => {
        console.log(event.target.value)
        dispatch(setLocalSetupItem({ k: LOCAL_SETUP_KEYS.THEME_DENSITY_NAME, v: event.target.value }))
    }

    return <>
        <Toolbar sx={{ justifyContent: 'center' }}>
            <ColorSchemeSelector />
        </Toolbar>
        <Box sx={{ p: 1 }}>

            <FormControl fullWidth>
                <Select
                    // labelId="demo-simple-select-label"
                    id="color_scheme_select"
                    value={densityName}
                    onChange={handleDensityChange}
                    // label="Age"
                    size="small"
                // variant="outlined"
                //   onChange={handleChange}
                >{
                        densityNames.map(name => <MenuItem key={name} value={name}><FormattedMessage id={'density_' + name} /></MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    </>
}