import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from '../store'
import { getAuth } from "firebase/auth";

export interface FBProfitUser {
    photoURL: string | null
    uid? : string
}

interface AuthState {
    user: FBProfitUser | undefined;
    status: 'loading' | 'idle';
}

const initialState : AuthState = {
    user: undefined,
    status: 'loading',
};

export const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser: (state, action: PayloadAction<FBProfitUser | undefined>) => {
            // console.log('saveUser', action.payload)
            state.user = action.payload;
            state.status = 'idle'
        },
    },
});

export const { saveUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthStatus = (state: RootState) => state.auth.status;

export async function getAuthToken() {
    const auth = getAuth()
    const user = auth.currentUser
    const token = await user!.getIdToken()
    return token
}

export function getAuthUserUid() {
    const auth = getAuth()
    const user = auth.currentUser
    return user!.uid
}

export default authSlice.reducer;