import { salesinvoices } from './registries/salesinvoices/salesinvoices'
import { pricelists } from './registries/pricelists/pricelists'
import { vat_report } from './reports/vat_report/vat_report'
import { sales_report } from './reports/sales_report/sales_report'
import { eefr_report } from './reports/eefr_report/eefr_report';
import { eefr_data } from './reports/eefr_data/eefr_data';

export const sales_regs = { salesinvoices, pricelists, vat_report, sales_report,
    eefr_report, eefr_data
 };

export type SalesApplicationUnit = 'salesinvoices' | 'pricelists' | 'vat_report' | 'sales_report' 
    | 'eefr_report' | 'eefr_data' // estonian RIK integration
    ;
