import React from 'react'
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
// import UnitEdit from './UnitEdit'
import { SimpleCodedNamedWithMemoRD } from '../../../../app/types/SimpleCodedNamedWithMemoRD';

const UnitEdit = React.lazy(() => import('./UnitEdit'))

class UnitsRD extends SimpleCodedNamedWithMemoRD {

    defaultDocument = {status: 0}
    
    getDetailForm = (docPath: string) =>  <UnitEdit docPath={docPath} />

    constructor() {
        super('units')
        this.selector.getOptionLabel = (option: SelectorListItem) => option.caption
    }
}

export const units = new UnitsRD()