export const flatten_objs = (doc: any, rows_collection_name: string, objs_collection_name: string) => {

    if (doc 
        && doc.collections 
        && doc.collections[rows_collection_name]
        && doc.collections[rows_collection_name].length
        && doc.collections[objs_collection_name]
        && doc.collections[objs_collection_name].length
    ) {
        const rows_collection = doc.collections[rows_collection_name];
        // const objs_collection = doc.collections[objs_collection_name];
        const ro : any[] = []


        rows_collection.forEach((row: any) => {
            // console.log('objs for row', row.nr)
            // console.log('obj_on_rows', ret.obj_on_rows)
            if(doc.obj_on_rows && row.objs) {
                if(row.objs.length)
                    row.objs.forEach((obj_id: number) => {
                        if(obj_id)
                            ro.push({
                                nr: row.nr,
                                obj_id: obj_id
                            })
                })
            } else
                if(doc.objs && doc.objs.length)
                    doc.objs.forEach((obj_id: number) => {
                        if(obj_id)
                            ro.push({
                                nr: row.nr,
                                obj_id: obj_id
                            })
                    })
        })

        return {
            ...doc,
            collections: {
                ...doc.collections,
                [objs_collection_name]: ro
            }
        }
        // ret.collections.purchaseinvoice_row_objects = ro
    }
    else
        return doc;
}

export const deflatten_objs =  (doc: any, rows_collection_name: string, objs_collection_name: string) => {
    let obj_on_rows = false;
    let last_objs_s = '';

    if (doc 
        && doc.collections 
        && doc.collections[rows_collection_name]
        && doc.collections[rows_collection_name].length
        && doc.collections[objs_collection_name]
        && doc.collections[objs_collection_name].length
    ) try {
        const rows_collection = doc.collections[rows_collection_name];
        const objs_collection = doc.collections[objs_collection_name];

        const new_rows_collection = rows_collection.map((row: any) => {
            const objs = objs_collection
                .filter((o: any) => o.nr === row.nr)
                .map((o: any) => o.obj_id)
            return {
                ...row,
                objs: objs,
                _objs_s: objs.sort().join(';')
            }
        });

        new_rows_collection.forEach((item : any) => {
            if(!item.__appended) {
                if(last_objs_s == '')
                    last_objs_s = item._objs_s
                if(item._objs_s != last_objs_s)
                    obj_on_rows = true;
            }
        });
   
        return {
            ...doc,
            obj_on_rows: obj_on_rows,
            objs: obj_on_rows ? [] : last_objs_s.split(';').map((s: string) => Number.parseInt(s)),
            collections: {
                ...doc.collections,
                [rows_collection_name]: new_rows_collection,            
            }
        };
    } catch (e) {
        console.warn('afterLoad', e);
    }
    
    return doc;
}