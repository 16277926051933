import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./EEFRDataReportReportForm'));

export const eefr_data_sections = ['EE0301010', 'EE0301020', 'EE0302010'];

const paramTypes : ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'sections': {type: 'text'}
};

class EEFRDataReportRD extends ReportDescriptor {

    reportEndpoint: string = 'eefr_data';
    name: ApplicationUnit = 'eefr_data';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['json'];
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'account_main_id', width: 140, label: 'account_main_id' },
            { name: 'account_main_desc', width: 200, label: 'account_main_desc' },
            { name: 'd', type: 'decimal', width: 100, align: 'right', label: 'd' },
            { name: 'c', type: 'decimal', width: 100, align: 'right', label: 'c' },
        ];
    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./EEFRReportPrintForm');
}

export const eefr_data = new EEFRDataReportRD();

