import { RegistryDescriptor } from "../../../app/types";
import * as Yup from 'yup';
import * as validators from "../../../app/validationUtils";
import { getFromCurrentDB } from "../../api";
import { store } from "../../../redux/store";
import { WAYBILL_TYPE } from "../../../app/const";
import { deflatten_objs, flatten_objs } from "../../utils";

export class WaybillsRD extends RegistryDescriptor {

    docEndpoint = 'waybills'

    defaultDocument = {
        // status: 1,
        // total_d: 0,
        // total_c: 0,
        // has_settlements: false,
        // deprecated_unbound: 0,
        // linked_document_id: 0,
        // deprecated_invoice: 0,
        // order_nr: 0,
        // deprecated_locked: 0,
        // with_currency: 0,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    default_row = {
        cost: 0,
        quantity: 1,
        price: 0,
        // date_paid: '3000-01-01',
        // obj_id: 0,
        // // quantity: 1,
        // // name: '',
        // // vat_return_percent: 0,
        // // vat_coef: 0,
        // // total_vat: 0
    }

    childrenDescriptors = {
        'collections/waybillrows': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: this.default_row,
        }
    }

    public isFilterable(): boolean { 
        return true
    }

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            date: validators.date_required(),
            src_wh_id: validators.integer_required_positive(),
            dst_wh_id: validators.integer_required_positive(),
            // ledger_type_id: validators.integer_required_positive(),
            // collections: Yup.object().shape({
            //     entries: Yup.array().of(
            //         Yup.object().shape({
            //             account_id: validators.integer_required_positive(),
            //             debit: Yup.number().min(0),
            //             credit: Yup.number().min(0),
            //         })
            //     )
            // })
        });
    }

    public isLocked = (doc: any): boolean => this.isLockable() && !!doc && !!doc.locked;

    public isReadOnly = (doc: any): boolean => doc && this.isLocked(doc);

    protected calc_doc_totals = (doc: any) => {
        let total_cost: number = 0;
        let total_price: number = 0;
        doc.collections.waybillrows.forEach((r: any) => {
            if(!isNaN(r.total_cost)) total_cost += r.total_cost;
            if(!isNaN(r.total_price)) total_price += r.total_price;
        })
        console.log('calc_doc_totals', total_cost, total_price)

        return { ...doc,  total_price: total_price, total_cost: total_cost};
    }

    protected calculate_row_statics = (doc: any, row: any) => {
        const quantity: number = isNaN(row.quantity) ? 0 : row.quantity;
        const cost: number = isNaN(row.cost) ? 0 : row.cost;
        const price: number = isNaN(row.price) ? 0 : row.price;
        const discount: number = isNaN(row.discount) ? 0 : row.discount;

        return {...row, 
            total_cost: quantity * cost,
            total_price: quantity * price * (1 - discount / 100),
        };
    }

    protected is_income_kind() {
        const t = this.get_type_id();
        return t === WAYBILL_TYPE.INCOME || t === WAYBILL_TYPE.INTRA || t === WAYBILL_TYPE.INIT;
    }

    protected is_outcome_type() {
        const t = this.get_type_id();
        return t === WAYBILL_TYPE.OUTCOME || t === WAYBILL_TYPE.WRITEOFF || t == WAYBILL_TYPE.INTRA;
    }

    /**
     * @brief when quantity is changed calculate totals
     */
    protected on_update_waybillrows_quantity = (doc: any, row: any, rowIndex: number, new_quantity: number) => {
        if(this.is_outcome_type())
            return this.fetch_row_data(doc, row);
        else
            return this.calculate_row_statics(doc, row);
    }

    /**
     * @brief when cost is changed calculate totals
     */
    protected on_update_waybillrows_cost = (doc: any, row: any, rowIndex: number, new_cost: number) => {
        return this.calculate_row_statics(doc, row)
    }

    protected on_update_waybillrows_price = (doc: any, row: any, rowIndex: number, new_price: number) => {
        return this.calculate_row_statics(doc, row)
    }

    protected on_update_waybillrows_discount = (doc: any, row: any, rowIndex: number, new_discount: number) => {
        return this.calculate_row_statics(doc, row)
    }

    private fetch_row_data = async (doc: any, row: any) => {
        let ret : any;
        if(row.article_id > 0) {
            const articleData = (await getFromCurrentDB(store.getState(), 'fetch_salesinvoice_rowdata', {
                article_id: row.article_id,
                buyer_id: doc.recipient_id,
                qty: this.is_outcome_type() ? row.quantity : 0,     // check cost only for certain types
                wh_id: row.src_wh_id > 1 ? row.src_wh_id : doc.src_wh_id,
            })).data[0];
            ret = {
                ...row,
                unit_id: articleData.unit_id,
                price: articleData.price,
                discount: articleData.discount,
                cost: articleData.cost ? articleData.cost : 0
            };
        } else
            ret = {
                ...row,
                unit_id: 0,
                price: 0,
                discount: 0
            };
        return this.calculate_row_statics(doc, ret);
    }

    protected on_update_waybillrows_article_id = async (doc: any, row: any, rowIndex: number, new_article_id: number) => {

        console.log('on_update_waybillrows_article_id', new_article_id)
        return this.fetch_row_data(doc, row);
    }

    private calc_new_nr = async (doc: any) => {

        if(!!doc.id || this.get_type_id() === WAYBILL_TYPE.INIT)
            return doc;

        const r = await getFromCurrentDB(
            store.getState(), 
            'get_enumeration_for_waybill', 
            {
                doc_date: doc.date, 
                type_id: this.get_type_id(),
                src_wh_id: doc.src_wh_id,
                dst_wh_id: doc.dst_wh_id
            } 
        );
        const d = r.data[0];
        console.log('retrieved enumeration:', d);

        return {
            ...doc,
            ...d,
            d_caption: this.get_d_caption(d)
        }
    }

    protected on_update_date = async (doc: any, val: any): Promise<any> => {
        return await this.calc_new_nr(doc);
    }

    // TODO check if this is needed depending on type_id
    protected on_update_src_wh_id = async (doc: any, val: any): Promise<any> => {
        return await this.calc_new_nr(doc);
    }

    // TODO check if this is needed depending on type_id
    protected on_update_dst_wh_id = async (doc: any, val: any): Promise<any> => {
        return await this.calc_new_nr(doc);
    }

    private get_d_caption = (doc: any) : string => doc.nrprefix + doc.nr + doc.nrsuffix;

    /**
     * calculate totals on every row update
     * @param doc 
     * @param field 
     * @param val 
     * @returns 
     */
    protected on_update_waybillrows = async (doc: any, field: string, val: any): Promise<any> =>
        this.calc_doc_totals(doc)
    // TODO maybe dont need to recalculate every time?

    protected on_delete_waybillrows = (doc: any, path: string): any =>
        this.calc_doc_totals(doc);


    protected check_type_specific = (doc: any) => {
        return doc;
    }
    
    public async beforeSave(doc: any): Promise<any> {

        const r1 = this.cleanupChildren(doc);
        const r2 = await this.validate(r1);
        const r3 = this.calc_doc_totals(r2);
        const r4 = this.check_type_specific(r3);

        // check and set rows wh ids
        if((r4.src_wh_id > 1 || r4.dst_wh_id > 1) && (r4.collections && r4.collections.waybillrows && r4.collections.waybillrows.length))
            r4.collections.waybillrows = r4.collections.waybillrows.map((r: any) => {
                return {
                    ...r,
                    src_wh_id: r4.src_wh_id > 1 ? r4.src_wh_id : r.src_wh_id || 1,
                    dst_wh_id: r4.dst_wh_id > 1 ? r4.dst_wh_id : r.dst_wh_id || 1
                }
            });

        const r5 = flatten_objs(r4, 'waybillrows', 'waybillrowobjects');

        return {
            ...r5,
            type_id: this.get_type_id()
        };
    }

    public async afterCopy(doc: any) { 
        return {
            ...doc,
            id: undefined,
            attachments_count: 0,
            nr: ''
        }
    }

    protected get_type_id() : number {
        throw new Error('Method not implemented.');
    }

    /**
     * @brief set up flags and collections after document is loaded
     * @param doc document, purchaseinvoice
     * @returns modified document
     */
    public async afterLoad(doc: any) {

        // console.log('afterLoad', doc)

        // await this.get_cached_units();

        doc.src_wh_on_rows = !!((doc.src_wh_id === 1) && (
            doc.type_id === WAYBILL_TYPE.OUTCOME
            || doc.type_id === WAYBILL_TYPE.WRITEOFF
            || doc.type_id === WAYBILL_TYPE.INTRA
            )
        );
        doc.dst_wh_on_rows = !!((doc.dst_wh_id === 1) && (
            doc.type_id === WAYBILL_TYPE.INIT
            || doc.type_id === WAYBILL_TYPE.INCOME
            || doc.type_id === WAYBILL_TYPE.INTRA
            )
        );

        const d2 = deflatten_objs(doc, 'waybillrows', 'waybillrowobjects');

        if(d2 && d2.collections && d2.collections.waybillrows && d2.collections.waybillrows.length) {
            d2.collections.waybillrows = d2.collections.waybillrows.map((r: any) => {        
                const r2 = this.calculate_row_statics(d2, r);
                return r2;
                });
        }

        // TODO load cached units

        return this.calc_doc_totals({
            ...this.defaultDocument,
            ...d2,
            d_caption: this.get_d_caption(d2)
        })
    }
}
