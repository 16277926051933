/**
 * ProfitCentralDatasets management
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { LoadableItemStatus } from '../../app/types';
import { useSelector } from 'react-redux';
import { getFromProfitCentral } from '../../profit/profitcentral';

export interface DatasetData {
    status: LoadableItemStatus
    error?: any
    data?: any
}

export interface DatasetsState {
    [key: string]: DatasetData
}

const initialState: DatasetsState = {}

interface DatasetsLoadingIndicators { [key: string]: boolean }

// Handle datasets loading
const loading: DatasetsLoadingIndicators = {}

export const loadDatasetFromProfitCentral = createAsyncThunk(
    'profitCentralDatasets/load',
    async (payload: string, thunkApi) => {

        // If already started loading, prevent duplicate loading
        if (loading[payload])
            return [];
        else
            loading[payload] = true;

        // payload is db/endpoint/?params
        const state = thunkApi.getState() as any;
        const response = await getFromProfitCentral(payload);
        return response.list;   // TODO why list?
    }
)

export const profitCentralDatasetsReducer = createSlice({
    name: 'profitCentralDatasets',
    initialState,
    reducers: {
        resetAllProfitCentralDatasets: (state, action) => {
            state = {};
        },
        resetProfitCentralDataset: (state, action) => {
            delete state[action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDatasetFromProfitCentral.pending, (state, action) => {
                const path = action.meta.arg;
                if (!state[path])
                    state[path] = { status: 'loading' };
            })
            .addCase(loadDatasetFromProfitCentral.fulfilled, (state, action) => {
                const path = action.meta.arg;
                state[path].data = action.payload;
                state[path].status = 'idle';
                loading[path] = false;
                //console.table(state[path].data);
            })
            .addCase(loadDatasetFromProfitCentral.rejected, (state, action) => {
                console.warn('dataset loading rejected', action);
                const path = action.meta.arg;
                state[path] = { status: 'error', error: action.error };
                loading[path] = false;
            })
    }
});

export const { resetAllProfitCentralDatasets, resetProfitCentralDataset } = profitCentralDatasetsReducer.actions;

export const selectAllProfitCentralDatasets = (state: RootState) => state.profitCentralDatasets;

export const selectProfitCentralDatasetState = (path: string): DatasetData =>
    useSelector((state: RootState) => state.profitCentralDatasets[path] || {status: 'invalid'});

export const selectProfitCentralDataset = (path: string): any =>
    useSelector((state: RootState) => state.profitCentralDatasets[path]?.data || undefined);

export const selectProfitCentralDatasetStatus = (path: string): LoadableItemStatus =>
    useSelector((state: RootState) => state.profitCentralDatasets[path]?.status || 'invalid');

export const selectProfitCentralDatasetError = (path: string): LoadableItemStatus =>
    useSelector((state: RootState) => state.profitCentralDatasets[path]?.error);

export default profitCentralDatasetsReducer.reducer;
