import React from 'react'
import { RegistryDescriptor } from "../../../../app/types";
import * as Yup from 'yup';
import { ApplicationUnit } from '../../../regs';

const ObjectEditForm = React.lazy(() => import('./ObjectForm'));

class ObjectsRD extends RegistryDescriptor {
    gridEndpoint = 'objects';
    docEndpoint = 'objects';
    selector = {
        endpoint: 'objects',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
    };
    name: ApplicationUnit = 'objects';
    columns =  [
        {name: 'code'},
        {name: 'name'},
        {name: 'memo'}
    ];

    defaultDocument = {status: 0};

    public getTitle(doc: any): string {
        return doc && doc.code ? doc.code + (doc.name ? ' : ' + doc.name : '') : ''
    }

    public getValidationSchema(): Yup.ObjectSchema<any> {
        return Yup.object().shape({
            code: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
        });
    }

    getDetailForm = (docPath: string) => <ObjectEditForm docPath={docPath}/>
}

export const objects = new ObjectsRD()