import React from "react";
import { DocGridColumn, KeyValueList, RegistryDescriptor } from "../../../../app/types";
import { updateDocFieldValue } from "../../../../redux/docs/utils";
import { useIntl } from "react-intl";
import { getFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";
import { INTL_DATEFORMAT, WAYBILL_TYPE } from "../../../../app/const";
import * as Yup from 'yup';
import { addDays, anyToType, getDaysBetweenDates, round2 } from "../../../../app/utils";
import * as validators from "../../../../app/validationUtils";
import { DocLink } from "../../../../redux/docLinks/docLinksSlice";
// import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../app/types/DocGridColumn";
// import GeneralLedgerAggPanel from "./GeneralLedgerAggPanel";
// import GeneralLedgerFilterForm from "./GeneralLedgerFilterForm";
import { ApplicationUnit } from "../../../regs";
import { WaybillsRD } from "../WaybillsRD";
import WhAggCostPanel from "../WhAggCostPanel";
import WhInitsFilterForm from "./WhInitsFilterForm";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";

const WhInitsEdit = React.lazy(() => import('./WhInitsEdit'));

class WhInitsRD extends WaybillsRD {

    // properties specific to this registry
    name: ApplicationUnit = 'wh_inits'

    gridEndpoint = 'wh_inits'
    selector = {
        endpoint: 'wh_inits',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'wh_name',
        textCol: 'date',
        memoCol: 'memo'
    }

    defaultDocument = {
        type_id: WAYBILL_TYPE.INIT,
        nrprefix: '',
        nrsuffix: '',
        src_wh_id: 1,
        dst_wh_id: 1,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    

    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? (!!doc.nr ? doc.nr : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    }

    getDetailForm = (docPath: string) => <WhInitsEdit docPath={docPath} />

    public getGridFooterAggPanel(agg: any): JSX.Element | null {
        return <WhAggCostPanel agg={agg} />
    }

    /**
     * @brief check and set wh init doc specific fields
     */
    protected check_type_specific = (doc: any) => {
        return {
            ...doc,
            nr: doc.dst_wh_id
        };
    }

    protected get_type_id = () : number => WAYBILL_TYPE.INIT;

    regFilterTranslations: RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_from: { field: 'total_cost', operator: '>=', type: 'decimal' },
        total_until: { field: 'total_cost', operator: '<=', type: 'decimal' },
        dst_wh_id: { field: 'wh_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        // object_id: { field: 'obj_id', operator: '=' },
        // currency_id: { field: 'currency_id', operator: '=' },
    }

    public getFilterForm(docPath: string): JSX.Element | null {
        return <WhInitsFilterForm docPath={docPath} />
    }

    // /**
    //  * @param setup General setup state object
    //  * @returns Filtered setup key-value pairs applicable to this type of document
    //  */
    // public getSetup(setup: KeyValueList): KeyValueList {
    //     // Override this method to apply setup to the document
    //     // console.log('salesinvoices.getSetup', setup)
    //     return {
    //         def_currency_id: setup['DEF.VALUUTAID'] || '0',
    //     }
    // }

    // public getSettingsSidebarItems() {
    //     return []
    // }

    // public async loadReportData(params: any): Promise<any> {
    //     return null
    // }

    columns: DocGridColumn[] = [
        // { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'doc_date', type: 'date', width: 100, label: 'date' },
        { name: 'wh_code', type: 'string', width: 30 },
        { name: 'wh_name', type: 'string', width: 120 },
        { name: 'total_cost', type: 'decimal', width: 100, align: 'right' },
        { name: 'memo', type: 'string', width: 200 },
        { name: 'summaryrows', type: 'string', width: 200 },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]


    // public isLockable(): boolean {
    //     return true;
    // }

    // public isLocked(doc: any): boolean {
    //     return doc && !!doc.status;
    // }

}

export const wh_inits = new WhInitsRD()