/**
 * General dataset management
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { get } from '../../profit/api';
import { LoadableItemStatus } from '../../app/types';
import { useSelector } from 'react-redux';

export interface DatasetData {
    status: LoadableItemStatus
    error?: any
    data?: any
}

export interface DatasetsState {
    [key: string]: DatasetData
}

// export interface DatasetsState {
//     list: DatasetsList
// }

const initialState: DatasetsState = {}

interface DatasetsLoadingIndicators { [key: string]: boolean }

// Handle datasets loading
const loading: DatasetsLoadingIndicators = {}

export const loadDataset = createAsyncThunk(
    'datasets/load',
    async (payload: string, thunkApi) => {

        // If already started loading, prevent duplicate loading
        if (loading[payload])
            return [];
        else
            loading[payload] = true;

        // payload is db/endpoint/?params
        const state = thunkApi.getState() as any;
        const response = await get(state, payload);
        return response.data;
    }
)

export const datasetsSlice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        resetAll: (state, action) => {
            state = {};
        },
        resetDataset: (state, action) => {
            delete state[action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDataset.pending, (state, action) => {
                const path = action.meta.arg;
                state[path] = { status: 'loading', data: undefined };
            })
            .addCase(loadDataset.fulfilled, (state, action) => {
                const path = action.meta.arg;
                state[path].data = action.payload;
                state[path].status = 'idle';
                loading[path] = false;
            })
            .addCase(loadDataset.rejected, (state, action) => {
                console.warn('dataset loading rejected', action);
                const path = action.meta.arg;
                state[path] = { status: 'error', error: action.error };
                loading[path] = false;
            })
    }
});

export const { resetAll, resetDataset } = datasetsSlice.actions;

export const selectAllDatasets = (state: RootState) => state.datasets;

export const selectDataset = (path: string): any =>
    useSelector((state: RootState) => state.datasets[path]?.data || undefined);

export const selectDatasetStatus = (path: string): LoadableItemStatus =>
    useSelector((state: RootState) => state.datasets[path]?.status || 'invalid');

export const selectDatasetError = (path: string) =>
    useSelector((state: RootState) => state.datasets[path]?.error);

export default datasetsSlice.reducer;
