import React from "react";
import { DocGridColumn } from "../../../../app/types";
import { useIntl } from "react-intl";
import { INTL_DATEFORMAT, WAYBILL_TYPE } from "../../../../app/const";
import { ApplicationUnit } from "../../../regs";
import { WaybillsRD } from "../WaybillsRD";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";

const WhInitsEdit = React.lazy(() => import('./WhIncomesEdit'));
const WhAggCostPanel = React.lazy(() => import('../WhAggCostPanel'));
const WhIncomesFilterForm = React.lazy(() => import('./WhIncomesFilterForm'));

class WhIncomesRD extends WaybillsRD {

    // properties specific to this registry
    name: ApplicationUnit = 'wh_incomes'

    gridEndpoint = 'wh_incomes'
    selector = {
        endpoint: 'wh_incomes',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'd_caption',
        textCol: 'date',
        memoCol: 'memo'
    }

    defaultDocument = {
        type_id: WAYBILL_TYPE.INCOME,
        nrprefix: '',
        nrsuffix: '',
        src_wh_id: 1,
        dst_wh_id: 1,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? (!!doc.nr ? doc.nr : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    }

    public getDetailForm = (docPath: string) => <WhInitsEdit docPath={docPath} />

    public getGridFooterAggPanel = (agg: any): JSX.Element | null => <WhAggCostPanel agg={agg} />

    protected get_type_id = () : number => WAYBILL_TYPE.INCOME;

    regFilterTranslations : RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_from: { field: 'total_cost', operator: '>=', type: 'decimal' },
        total_until: { field: 'total_cost', operator: '<=', type: 'decimal' },
        dst_wh_id: { field: 'wh_id', operator: '=', type: 'selected_id_int' },
        supplier_id: { field: 'supplier_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        obj_id: { field: 'obj_id', operator: '=', type: 'selected_id_int' },
    }

    public getFilterForm = (docPath: string): JSX.Element | null => <WhIncomesFilterForm docPath={docPath} />

    columns: DocGridColumn[] = [
        { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'd_caption', type: 'string', width: 80, label: 'nr' },
        { name: 'doc_date', type: 'date', width: 100, label: 'date' },
        { name: 'wh_name', type: 'string', width: 150 },
        { name: 'supplier_name', type: 'string', width: 150 },
        { name: 'total_cost', type: 'decimal', width: 100, align: 'right' },
        { name: 'purchase_invoice_nr', type: 'string', width: 100, label: 'invoice_nr' },
        { name: 'doc', type: 'string', width: 200 },
        { name: 'memo', type: 'string', width: 200 },
        { name: 'summaryrows', type: 'string', width: 200 },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public isLockable = (): boolean => true;
}

export const wh_incomes = new WhIncomesRD()