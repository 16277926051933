import React, {KeyboardEvent} from 'react';

import { Skeleton, TextField } from "@mui/material"
import { useAppDispatch } from '../../../app/hooks';
import { EditFlavor, TextAlignOption, TextEditType } from '../../../app/types';
import { useIntl } from 'react-intl';
import { useDataConnector } from '../../../redux/store';
import EnhancedFormControl from './EnhancedFormControl';
import { anyToType } from '../../../app/utils';
import InputSkeleton from '../InputSkeleton';


interface BaseTextEditProps {
    docPath: string
    field: string
    label?: string
    flavor?: EditFlavor
    type?: TextEditType
    multiline?: boolean
    fullWidth?: boolean
    autoFocus?: boolean
    textAlign?: TextAlignOption
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const convertTypeToInputType = (type?: TextEditType) => (type == 'number' || type == 'int') ? 'number' : 'text'

export default function BaseTextEdit ({docPath, field, label, flavor, type, multiline, fullWidth, autoFocus, textAlign }: BaseTextEditProps) {

    const dispatch = useAppDispatch()
    const dc = useDataConnector(docPath)
    const obj = dc.selectRecord(docPath)
    const isReady = dc.isRecordReadyForEdit(docPath)
    const error = dc.getValidationError(docPath, field)
    const isReadOnly = dc.isReadOnly(docPath)

    const dv = dc.getValue(obj, field);
    const defaultValue = anyToType(dv === undefined ? '' : dv, type);
    const [val, setVal] = React.useState(defaultValue);

    React.useEffect(() => {
        setVal(defaultValue)
    }, [defaultValue])

    const handleKeyUp = (e: KeyboardEvent<HTMLImageElement>) => {
        if(e.key === 'Enter')
            handleSubmit()
        if(e.key === 'Escape') 
            setVal(defaultValue)
    }

    const handleSubmit = () => {
        const convertedValue = anyToType(val, type)
        return convertedValue !== defaultValue && dispatch(dc.updateFieldData({docPath, field, val: convertedValue}));
    }

    const intl = useIntl();
    const labelMsg = label === undefined ? field : label;

    const controlType = convertTypeToInputType(type)

    return <EnhancedFormControl
        docPath={docPath}
        field={field}
        flavor={flavor}
        fullWidth
    >{
        isReady ? <TextField 
            inputProps={textAlign ? {min: 0, style: { textAlign: textAlign }} : undefined}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            multiline={multiline}
            disabled={isReadOnly}
            label={labelMsg ? intl.formatMessage({id: labelMsg}): undefined}
            variant={flavor === 'grid' ? 'standard' : 'outlined'}
            autoComplete='none'
            size='small'
            type={controlType}

            sx={controlType === 'number' ? {
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
              } : undefined}

            value={typeof(val) === 'undefined' ? defaultValue : val}
            onChange={(e) => setVal(e.target.value)}
            error={!!error}
            helperText={!!error ? intl.formatMessage({id: error}) : undefined}
            onKeyUp={handleKeyUp}
            onBlur={handleSubmit}
            onFocus={e => {e.target.select()}}
        />: <InputSkeleton width={fullWidth ? '100%' : 200} />
    }</EnhancedFormControl>
}