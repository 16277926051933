import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { paramTypes } from "./const";
import { ApplicationUnit } from "../../../regs";
import { ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./WhStateReportForm'));

class WhStateReportRD extends ReportDescriptor {

    reportEndpoint: string = 'wh_state_report';
    name: ApplicationUnit = 'wh_state_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['pdf', 'json', 'table'];
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'article_code', width: 120, label: 'code' },
            { name: 'article_name', width: 200, label: 'name' },
            { name: 'unit_code', width: 80, label: 'unit' },
            { name: 'group_name', width: 80, label: 'group' },
            { name: 'qty', type: 'decimal', width: 100, align: 'right', label: 'wh_qty' },
            { name: 'cost', type: 'decimal', width: 100, align: 'right', label: 'cost' },
            { name: 'total', type: 'decimal', width: 100, align: 'right', label: 'total' },
        ];
    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};   //TODO: order selector?
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./WhStateReportPrintForm');

}

export const wh_state_report = new WhStateReportRD();