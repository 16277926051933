import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { get } from '../../profit/api';
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { RegistryFilterOperator } from '../../app/types/types';

export interface GridSetup {
    pagination: GridPaginationModel
    sorting: GridSortModel
    filter: GridFilterModel                     // filter model
    regFilter?: RegistryFilterOperator[]        // registry filter, key/value pairs
}

export interface GridData {
    status: 'invalid' | 'loading' | 'idle' | 'error'
    pages: any[]
    lastError?: any
    agg?: any
    rowCount?: number
}

export interface GridsList {
    [key: string]: GridData
}

export interface GridsSetupList {
    [key: string]: GridSetup
}

export interface GridsState {
    data: GridsList
    setup: GridsSetupList
    lastError?: any
}

const initialState: GridsState = {
    data: {},
    setup: {},
}

const defaultPagination: GridPaginationModel = {
    page: 0,
    pageSize: 100,
}

const defaultFilter: GridFilterModel = {
    items: []
}

const defaultSorting: GridSortModel = []

interface GridLoadOperatorTranslations {
    [key: string]: string
}

const gridFilterOperatorTranslations: GridLoadOperatorTranslations = {
    'contains': 'like',
    'equals': '=',
    'startsWith': 'like',
    'endsWith': 'like',
    'isEmpty': '=',
    'isNotEmpty': '<>',
    'isAnyOf': 'in',
}

export const loadGrid = createAsyncThunk(
    'grids/load',
    async (payload: string, thunkApi): Promise<GridData> => {

        const state = thunkApi.getState() as RootState

        const name = payload

        let data: GridData = state.grids.data[name] as GridData
        let setup: GridSetup = state.grids.setup[name] as GridSetup

        // If grid status is error, return early until grid is invalidated
        if(data.status === 'error')
            return data

        // If idle and page is already loaded, return early
        if(data.status === 'idle' && data.pages[setup.pagination.page])
            return data

        const nf: RegistryFilterOperator[] = []
        if(setup.regFilter) // TODO is it the most efficient way to do this?
            setup.regFilter.forEach(f => {nf.push(f)})

        setup.filter.items.forEach(f => {

            if(!!f.field && !!f.value) {
                let value
                switch(f.operator) {
                    case 'contains':
                        value = `%${f.value}%`
                        break
                    case 'startsWith':
                        value = `${f.value}%`
                        break
                    case 'endsWith':
                        value = `%${f.value}`
                        break
                    case 'isEmpty':
                    case 'isNotEmpty':
                        value = ''
                        break
                    case 'isAnyOf':
                        value = f.value.join(',')
                        break
                    default:
                        value = f.value
                }

                try {
                nf.push({
                    field: f.field,
                    operator: gridFilterOperatorTranslations[f.operator],
                    value: value
                })
                } catch(e) { console.log(e)}
            }
        })

        const query: any = {
            _pageNo: setup.pagination.page,
            _pageLength: setup.pagination.pageSize,
        }

        if(setup?.sorting[0]?.field)
            query._orderBy = setup?.sorting[0]?.field
        if(setup?.sorting[0]?.sort)
            query._sortDir = setup?.sorting[0]?.sort

        if(nf && nf.length > 0)
            query._filter = JSON.stringify(nf)

        const response = await get(state, name, query);


        const newPages = [...data.pages]
        newPages[setup.pagination.page] = response.data

        return {
            ...data, 
            pages: newPages,
            rowCount: response.agg.count,
            agg: response.agg,
            status: 'idle'
        }
    }           
)

export const selectorsSlice = createSlice({
    name: 'grids',
    initialState,
    reducers: {

        setSorting: (state, action) => {
            const {name, sorting} = action.payload as {name: string, sorting: GridSortModel}
            state.setup[name].sorting = sorting
            state.data[name].status = 'invalid'
            // console.log('new sorting for ' + name + ': ', state.setup[name].sorting)
        },

        setPagination: (state, action) => {
            const { name, pagination } = action.payload as {name: string, pagination: GridPaginationModel}
            state.setup[name].pagination = pagination
            state.data[name].status = 'invalid'
            // console.log('new pagination for ' + name + ': ', state.setup[name].pagination)
        },

        setFilter: (state, action) => {
            const { name, filter } = action.payload as {name: string, filter: GridFilterModel}
            state.setup[name].filter = filter
            state.data[name].status = 'invalid'
            // console.log('new filter for ' + name + ': ', state.setup[name].filter)
        },

        setRegFilter: (state, action: PayloadAction<{name: string, value: RegistryFilterOperator[]}>) => {
            const name = action.payload.name;
            // console.log('setRegFilter', name);
            state.setup[name].regFilter = action.payload.value;
            if(state.data[name])
                state.data[name].status = 'invalid';
        },

        resetAll: (state, action) => {
            state.data = {}
            state.lastError = undefined
        },
        resetGrid: (state, action) => {
            const name = action.payload;
            // console.log('resetGrid', name);
            delete state.data[name]
            state.lastError = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadGrid.pending, (state, action) => {
                const name = action.meta.arg;
                // console.log('PENDING loadGrid', name);
                if(!state.data[name])
                    state.data[name] = {
                        status: 'loading',
                        pages: []
                    }
                else {
                    state.data[name].status = 'loading'
                    state.data[name].lastError = undefined
                    state.lastError = undefined    
                }

                if(!state.setup[name])
                    state.setup[name] = {
                        pagination: defaultPagination,
                        sorting: defaultSorting,
                        filter: defaultFilter
                    }
            })
            .addCase(loadGrid.fulfilled, (state, action) => {
                const name = action.meta.arg
                // console.log('FULFILLED loadGrid', name);
                state.data[name] = action.payload
            })
            .addCase(loadGrid.rejected, (state, action) => {
                const name = action.meta.arg
                console.warn('rejected for ', name, action.error)
                state.data[name] = {
                    ...state.data[name],
                    status: 'error',
                    lastError: action.error
                }
                state.lastError = action.error
            })
    }
})

export const { resetAll, resetGrid, setPagination, setSorting, setFilter, setRegFilter } = selectorsSlice.actions

export const selectGridsData = (state: RootState) => state.grids.data
export const selectGridsSetup = (state: RootState) => state.grids.setup

export const selectGridDataByKey = (key: string) : GridData => 
    useSelector((state: RootState) => {
        // console.log('selectGridDataByKey(' + key + ') --> ', state.grids.data[key]);
        return state.grids.data[key];
    });

export const selectGridSetupByKey = (key: string) =>
    useSelector((state: RootState) => state.grids.setup[key])

// export const selectGridDataWithSetup = (key: string) => {
//     return useSelector((state: RootState) => {
//         return {
//             grid: state.grids.data[key],
//             setup: state.grids.setup[key]
//         }
//     });
// }

export default selectorsSlice.reducer
