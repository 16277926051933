import { PaletteOptions, ThemeOptions, createTheme } from "@mui/material";
import defaultTheme, { defaultPalette } from "./defaultTheme"
import { get } from "lodash";

export const colorSchemeNames = ['system', 'light', 'dark']
export const densityNames = ['default', 'comfortable', 'compact']

export const getPalette = (name: string): PaletteOptions => {
    switch (name) {
        case 'light':
            return defaultPalette;
        case 'dark':
            return { 
                ...defaultPalette, 
                background: { default: '#202020' },
                mode: 'dark' 
            }
        default:
            return defaultPalette;
    }
}

export const getDensity = (name: string) => {
    switch (name) {
        case 'compact':
            return {
                fontSize: 11,
                spacing: 1,
            }
        case 'comfortable':
            return {
                fontSize: 12,
                spacing: 2,
            }
        default:
            return {
                fontSize: 14,
                spacing: 4,
            }
    }
}

export const getTheme = (paletteName: string, densityName: string) => {
    const densityData = getDensity(densityName)

    const pl = densityData.spacing / 2

    // console.log('pl', pl)

    const thm : ThemeOptions = { 
        ...defaultTheme, 
        palette: getPalette(paletteName),
        spacing: (factor: number) => `${2 * factor}px`,
        // spacing: [0, 4, 8, 16, 32, 64],
        // components: {
        //     MuiInputBase: {
        //         styleOverrides: {
        //             root: ( { theme} ) => 
        //                 theme.unstable_sx({

        //                     px: 0,
        //                     py: 0.25,   
        //                 })
        //         }
        //     },
        //     MuiTextField: {
        //         styleOverrides: {
        //             root: ( { theme} ) => 
        //                 theme.unstable_sx({
        //                     // px: 0,
        //                     // py: 0.25,   
        //                 })
        //         }
        //     },

        //     MuiTableCell: {
        //         styleOverrides: {
        //             root: ( { theme} ) =>
        //                 theme.unstable_sx({
        //                     // pl: pl < 1 ? 1 : pl,
        //                     // pt: pl < 1 ? 1 : pl,
        //                     // pb: pl < 1 ? 1 : pl,
        //                     // pr: pl < 1 ? 1 : pl,
        //                 })
        //         }
        //     },

        //     MuiOutlinedInput: {
        //         styleOverrides: {
        //             input: ( { theme} ) =>
        //                 theme.unstable_sx({
        //                     pl: pl < 1 ? 1 : pl,
        //                     pt: pl < 1 ? 1 : pl,
        //                     pb: pl < 1 ? 1 : pl,
        //                     pr: pl < 1 ? 1 : pl,
        //                 })
        //         }
        //     }
        // },
    }
    if(densityData?.fontSize)
        thm.typography = {
            fontSize: densityData.fontSize
        }
    const theme = createTheme(thm)

    if(densityData?.spacing !== undefined) {
        // console.log('spacing set', densityData.spacing)
        theme.spacing(densityData.spacing)
        // theme.spacing = (n: number) => n * densityData.spacing
    }
    // console.log(thm)



    return theme
}