import { PATH_DELIMITER } from "../../app/utils";
import { ApplicationUnit } from "../../profit/regs";

export type DocPath = {
    dbName: string
    regName: ApplicationUnit
    id: string
}

/**
 * splits a doc path into dbName, regName and id
 * db/reg/id -> {dbName, regName, id}
 * @param docPath ie 'adt/salesinvoices/524'
 * @returns ie {dbName: 'adt', regName: 'salesinvoices', id: '524'}
 */
export const splitDocPath = (docPath: string): DocPath => {
    const parts = docPath.split('/');
    if (parts.length === 3) {
        // const [dbName, regName, id] = parts
        const ret = {
            dbName : parts[0],
            regName: parts[1] as ApplicationUnit,
            id: parts[2]
        }
        return ret
    }
    throw new Error('Invalid doc path: ' + docPath);
}

/**
 * combines dbName, regName and id into a doc path
 * @param dbName ie 'adt'
 * @param regName ie 'salesinvoices'
 * @param id ie '524'
 * @returns ie 'adt/salesinvoices/524'
 */
export const buildDocPath = (dbName: string, regName: string, id: string) =>
    dbName + '/' + regName + '/' + id

/**
 * updates complex document field value using path notation ie 'document/rows/0/amount'
 * where 'document' is the document object, 
 * 'rows' is an array of objects, 
 * '0' is the first element of the array 
 * and 'amount' is a field of the object.
 * Sets __dirty flag to true to indicate that the document has been modified.
 * @param record document
 * @param field path to the field ie 'document/rows/0/amount'
 * @param val value to set
 * @returns modified document
 * @todo change __dirty to __modified?
 */
export const updateDocFieldValue = (record: any, field: string, val: any) => {
    const ret = updateDocFieldValue_rec(record, field, val)
    if (ret.length)
        return ret
    else
        return { ...ret, __dirty: true }
}


// recursive helper function for updateDocFieldValue
const updateDocFieldValue_rec = (record: any, field: string, val: any) => {
    const sepPos = field.indexOf(PATH_DELIMITER)
    let newVal = val
    let fp = field
    if (sepPos > 0) {
        fp = field.substring(0, sepPos)
        newVal = updateDocFieldValue_rec(record[fp], field.substring(sepPos + 1), val)
    }

    if (record.length) {
        const ra = [...record]
        ra[Number.parseInt(fp)] = newVal
        return ra;
    }
    else
        return { ...record, [fp]: newVal };
}