import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { etEE } from '@mui/material/locale';

export const defaultPalette = {
    background: { default: '#ffffff', },
    primary: { main: '#556cd6', },
    secondary: { main: '#19857b', },
    error: { main: red.A400, },
}

// A custom theme for this app
const defaultTheme = {
  palette: defaultPalette,
  typography: {
    fontSize: 14,
  },
  // spacing: 1,

  // typography: {
  //   fontSize: 12,
  // },

  overrides: {
    MuiDataGrid: {
      root: {
        // Adjust the padding as needed
        '& .MuiDataGrid-cell': {
          padding: '1px', // Customize the padding value
        },
      },
    },
  }

  // components: {
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         padding: 0,
  //       }
  //     }
  //   },
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         textTransform: 'none',
  //         fontSize: 12,
  //         // borderSpacing: 0,
  //         // padding: 0,
  //       }
  //     }
  //   }
  // },



}
// , etEE);

// theme.spacing(0);

export default defaultTheme;
