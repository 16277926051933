import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const sales_report_sort_orders = [
    {value: 'doc_date, buyer_name, doc_nr', label: 'doc_date, buyer_name, doc_nr'},
    {value: 'buyer_name, doc_date, doc_nr', label: 'buyer_name, doc_date, doc_nr'},
    {value: 'doc_date, doc_nr, buyer_name', label: 'doc_date, doc_nr, buyer_name'}
];

export const paramTypes: ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'buyer_id': {type: 'select', selectorRd: 'companies', title: 'buyer'},
    'payer_id': {type: 'select', selectorRd: 'companies', title: 'payer'},
    'supplier_id': {type: 'select', selectorRd: 'companies', title: 'supplier'},
    'article_id': {type: 'select', selectorRd: 'articles', title: 'article'},
    'object_id': {type: 'select', selectorRd: 'objects', title: 'object'},
    'vat_id': {type: 'select', selectorRd: 'vats', title: 'vat'},
    'grp_id': {type: 'select', selectorRd: 'articlegroups', title: 'articlegroup'},
    'sort_order': {type: 'options', items: sales_report_sort_orders, title: 'sort_order'}
    // TODO koostaja 
    // TODO kassa
    // TODO kaubamärk
};
