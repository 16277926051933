import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { LoadableItemStatus, ProfitUser } from '../../app/types';
import { useSelector } from 'react-redux';
import { getMyProfile, saveMyName } from '../../profit/profitcentral';


export interface UserProfileState {
    user?: ProfitUser
    status: LoadableItemStatus      // status of users list
    error?: string                  // error message if status is error
}


const initialState: UserProfileState = {
    status: 'invalid'
}

export const loadProfile = createAsyncThunk(
    'userProfile/load',
    async (payload: string, thunkApi) => await getMyProfile()
)

export const saveUserName = createAsyncThunk(
    'userProfile/saveUserName',
    async (payload: string, thunkApi) => await saveMyName(payload)
)

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        resetProfile: (state, _) => {
            console.log('reset userProfile')
            state.status = 'invalid'
        },
        resetError: (state, _) => {
            console.log('reset userProfile error')
            state.error = undefined
            state.status = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProfile.pending, (state, action) => {
                // console.log('userProfile pending', action)
                state.status = 'loading'
            })
            .addCase(loadProfile.fulfilled, (state, action) => {
                // console.log('loadProfile fulfilled', action.payload)
                state.user = action.payload.profile
                state.status = 'idle'
            })
            .addCase(loadProfile.rejected, (state, action) => {
                // console.log('loadProfile rejected', action)
                state.status = 'error'
                state.error = action.error.message
            })

            .addCase(saveUserName.pending, (state, action) => {
                // console.log('saveUserName pending', action)
                state.status = 'loading'    // TODO wrong state?
            })
            .addCase(saveUserName.fulfilled, (state, action) => {
                // console.log('saveUserName fulfilled', action)
                state.user = action.payload.profile
                state.status = 'idle'
            })
            .addCase(saveUserName.rejected, (state, action) => {
                // console.log('saveUserName rejected', action)
                state.status = 'error'

                // TODO better error handling
                if(action.error.message?.includes('Integrity constraint violation: 1062 Duplicate entry'))
                    state.error = 'msg_user_name_not_unique';
                else
                    state.error = action.error.message;
            })
    }
});

export const { resetProfile, resetError } = userProfileSlice.actions;

export const selectMyProfile = (): ProfitUser | undefined =>
    useSelector((state: RootState) => state.userProfile.user?.uid === state.auth.user?.uid && state.userProfile.user || undefined)

export const selectMyProfileStatus = (): LoadableItemStatus =>
    useSelector((state: RootState) => state.userProfile.user?.uid === state.auth.user?.uid ? state.userProfile.status : 'invalid')

export const selectMyProfileLastError = (): string | undefined =>
    useSelector((state: RootState) => state.userProfile.user?.uid === state.auth.user?.uid ? state.userProfile.error : undefined)

export default userProfileSlice.reducer;
