import React from 'react';
import { Alert, Button, Typography } from "@mui/material"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormattedMessage } from 'react-intl';

interface ErrorBoxProps {
    title?: string
    message: string,
    messageValues?: any,
    onClose?: () => void,
    actions?: {
        label: string
        action: () => void
    }[]
}

export default function ErrorBox({ title, message, messageValues, onClose, actions }: ErrorBoxProps) {
    return <Alert
        icon={<ErrorOutlineIcon sx={{mt: 5, fontSize: 30 }} />}
        severity='error'
        onClose={onClose}
        sx={{ m: 2 }}
        action={actions ? actions.map((a, i) => <Button key={i} color="inherit" size="small" onClick={a.action} variant='outlined'>
            <FormattedMessage id={a.label} />
        </Button>) : undefined}
    >
        {title && <Typography variant='h6'><FormattedMessage id={title} /></Typography>}
        <Typography variant="caption" display="block" gutterBottom>
            <FormattedMessage
                id={message || 'error'}
                values={messageValues}
            />
        </Typography>
    </Alert>
}
