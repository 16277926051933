import React from "react";
import { ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { paramTypes } from "./const";
import { ApplicationUnit } from "../../../regs";
import { ReportDataDescriptor, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";

const Form = React.lazy(() => import('./SalesReportForm'));

class SalesReportRD extends ReportDescriptor {

    reportEndpoint: string = 'sales_report';
    name: ApplicationUnit = 'sales_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};   //TODO: order selector?
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./SalesReportPrintForm');

}

export const sales_report = new SalesReportRD();