import { PayloadAction, SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState, store } from '../store'
import { get } from '../../profit/api';
import { LoadableItemStatus, KeyValueList } from '../../app/types';
import { useSelector } from 'react-redux';
import { UpdateFieldDataPayload } from '../../app/types/DataConnector';

export interface CompanySharedSetup {
    setup: KeyValueList
    status: LoadableItemStatus
    lastError?: SerializedError
}

interface CompanySharedSetups { [db: string]: CompanySharedSetup }

export interface SharedSetupState { setups: CompanySharedSetups }

const initialState: SharedSetupState = { setups: {} }

export const getSharedSetupValue = async (key: string) => {
    const state = store.getState() as RootState
    return await getSharedSetupValue2(state.databases.currentDatabase!.uri, key)
}

export const getSharedSetupValue2 = async (db: string, key: string) => {
    const state = store.getState() as RootState
    if(!state.sharedSetup.setups[db] || state.sharedSetup.setups[db].status === 'invalid')
        await store.dispatch(loadSharedSetup(db))
    return state.sharedSetup.setups[db].setup[key]
}

export const loadSharedSetup = createAsyncThunk(
    'sharedSetup/loadSharedSetup',
    async (db: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const response = await get(state,  db + '/general_setup', {_fetch: 'all' })
        return { data: response.data }
    }
)

export const setSharedSetupValue = createAsyncThunk(
    'sharedSetup/setSharedSetupValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const parts = docPath.split('/')
        const response = await get(state,  parts[0] + '/update_general_setup', {name: field, text: val })
        return { data: response.data }
    }
)

export const sharedSetupSlice = createSlice({
    name: 'sharedSetup',
    initialState,
    reducers: {
        invalidate: (state, action: PayloadAction<string>) => {
            delete state.setups[action.payload]
        },
        invalidateAll: (state) => {
            state.setups = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSharedSetup.pending, (state, action) => {
                state.setups[action.meta.arg] = {status: 'loading', setup: {}}
                // console.log('pending', action)
            })
            .addCase(loadSharedSetup.fulfilled, (state, action) => {
                state.setups[action.meta.arg].status = 'idle'
                state.setups[action.meta.arg].setup = {}
                action.payload!.data.forEach((item: any) => {
                    state.setups[action.meta.arg].setup[item.key] = item.value
                })
                // console.log('fulfilled', action.payload)
            })
            .addCase(loadSharedSetup.rejected, (state, action) => { 
                console.warn(state, action);
                state.setups[action.meta.arg].status = 'error'
                state.setups[action.meta.arg].setup = {}
                state.setups[action.meta.arg].lastError = action.error
            })

            .addCase(setSharedSetupValue.pending, (state, action) => {
                // console.log('pending save setup', action)
            })
            .addCase(setSharedSetupValue.fulfilled, (state, action) => {
                // console.log('fulfilled save setup', action.payload)
                action.payload!.data.forEach((item: any) => {
                    const db = action.meta.arg.docPath.split('/')[0]
                    action.payload!.data.forEach((item: any) => {
                        state.setups[db].setup[item.updated_name] = item.updated_value
                    })    
                })
            })
            .addCase(setSharedSetupValue.rejected, (state, action) => {
                console.warn('setSharedSetupValue.rejected', state, action);
            })
    },
});

export const { invalidate, invalidateAll } = sharedSetupSlice.actions;
export const selectSharedSetupError = (db: string): any =>
    useSelector((state: RootState) => state.sharedSetup.setups[db]?.lastError)
export const selectSharedSetup = (db: string): KeyValueList =>
    useSelector((state: RootState) => state.sharedSetup.setups[db]?.setup || {})
export const selectSharedSetupStatus = (db: string | undefined): LoadableItemStatus =>
    useSelector((state: RootState) => db && state.sharedSetup.setups[db]?.status || 'invalid')

export const selectSharedSetupValue = (db: string, key: string): string | undefined => 
    useSelector((state: RootState) => state.sharedSetup.setups[db]?.setup[key])

export default sharedSetupSlice.reducer;