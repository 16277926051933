import React from "react"
import { FormControl, Tooltip } from "@mui/material";
import { splitDocPath } from "../../../redux/docs/utils";
import { useIntl } from "react-intl";
import { FIELD_SEL } from "../../../app/types";
import { selectLocalSetupValue } from "../../../redux/localSetup/localSetupSlice";
import { LOCAL_SETUP_KEYS } from "../../../app/const";

interface EnhancedFormControlProps {
    docPath: string
    field: string
    flavor?: string
    fullWidth?: boolean
    children: React.ReactNode
}

const shortHelpMessageId = (docPath: string, field: string) => {
    const dp = splitDocPath(docPath)
    let scalarDoc: string
    let finalFieldP2: string

    if (dp.regName === 'userSetupVar' || dp.regName === 'userSetupVarLocallySaved')
        scalarDoc = dp.id.toLowerCase()
    else if (dp.regName === 'report')
        scalarDoc = dp.id
    else
        scalarDoc = dp.regName!

    if (field.startsWith('collections/')) {
        const fieldParts = field.split('/')

        if (fieldParts[fieldParts.length - 1] === FIELD_SEL)
            return 'row' + FIELD_SEL

        const scalarField = fieldParts.length ? fieldParts[1] + '_' + fieldParts[fieldParts.length - 1] : ''
        finalFieldP2 = scalarField
    } else if (dp.regName === 'report') {
        const fieldParts = field.split('.')
        finalFieldP2 = fieldParts[fieldParts.length - 1]
    } else {
        finalFieldP2 = field
    }

    return scalarDoc + '_' + finalFieldP2
}

const ShortHelpTooltip = ({ docPath, field, children }: { docPath: string, field: string, children: React.ReactElement<any, any> }) => {
    const intl = useIntl()
    const msg = 'shorthelp_' + shortHelpMessageId(docPath, field)
    const popoverText = intl.formatMessage({
        id: msg,
        defaultMessage: 'TODO docPath=' + docPath + '; field=' + field + ' -> ' + msg,
    }, {
        'br': <br />,
    })
    return <Tooltip title={popoverText} placement='top-end' arrow>{children}</Tooltip>
}

/**
 * Enhanced form control with tooltip (text derived from docPath ie registry and field)
 * Experimental, see commented code of popover
 */
export default function EnhancedFormControl({ docPath, field, flavor, fullWidth, children, ...other }: EnhancedFormControlProps) {

    // TODO will caching here improve performance?
    const showHints = selectLocalSetupValue(LOCAL_SETUP_KEYS.UI_HINTS_SHOW) == '1'

    return showHints
        ? <ShortHelpTooltip docPath={docPath} field={field}><FormControl
            fullWidth={fullWidth}
            margin={flavor === 'grid' ? 'none' : 'dense'}
            size='small'
            {...other}
        >{children}</FormControl>
        </ShortHelpTooltip>
        : <FormControl
            fullWidth={fullWidth}
            margin={flavor === 'grid' ? 'none' : 'dense'}
            size='small'
            {...other}
        >{children}</FormControl>
}