import {RegistryDescriptor} from '../app/types'

import regs from './regs'
import { ApplicationUnit } from './regs'
import { ReportDescriptor } from '../app/types/ReportDescriptor'
import { ApplicationUnitDescriptor } from '../app/types/ApplicationUnitDescriptor'
import { splitDocPath } from '../redux/docs/utils'



export default regs;
export const unknownPage = new RegistryDescriptor()
export const unknownReport = new ReportDescriptor()

export function getApplicationUnitDescriptor(path: ApplicationUnit): ApplicationUnitDescriptor {
    return path && regs[path] ? regs[path] : unknownPage
}

export function getRegDescriptor(reg: ApplicationUnit): RegistryDescriptor {
    if (!!reg && regs[reg] !== undefined )
        try {
            return regs[reg] as RegistryDescriptor
        } catch (e) {
            console.warn('***found for ' + reg + ' is not RegistryDescriptor', e)
        }
    console.warn('***RegistryDescriptor not found for ' + reg)
    return unknownPage
}

export function getReportDescriptor(path: ApplicationUnit): ReportDescriptor {
    if (!!path && regs[path] !== undefined )
        try {
            return regs[path] as ReportDescriptor
        } catch (e) {
            console.warn('***found for ' + path + ' is not ReportDescriptor', e)
        }
    console.warn('***ReportDescriptor not found for ' + path)
    return unknownReport
}

export function getRegDescriptorByPath(path: string | undefined): RegistryDescriptor {
    if(!path)
        return unknownPage
    const p = splitDocPath(path)
    return getRegDescriptor(p.regName)
}