import { AsyncThunkAction } from "@reduxjs/toolkit";

export interface UpdateFieldDataPayload {
    docPath: string
    field: string
    val: any
}

export abstract class AbstractDataConnector {
    public abstract isForDocPath(docPath: string): boolean
    public abstract selectRecord(docPath: string): any
    public abstract updateFieldData(payload: UpdateFieldDataPayload): AsyncThunkAction<any, UpdateFieldDataPayload, {}>
    public abstract isRecordReadyForEdit(docPath: string): boolean
    public abstract getValue(obj: any, field: string): any
    public abstract getValidationError(docPath: string, fieldPath: string): string | undefined
    public abstract isReadOnly(docPath: string): boolean
}
