import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { LoadableItemStatus } from '../../app/types';
import { useSelector } from 'react-redux';
import { getFromProfitCentral } from '../../profit/profitcentral';

export interface OtherUser {
    id: number
    uid: string
    name: string
    status?: 'undefined' | 'creating' | 'deleting' | 'normal' | 'creation failed' | 'deletion failed'
}

type UserManagementStatus = LoadableItemStatus | 'adding' | 'deleting'

export interface UserManagementState {
    db?: string                     // current database name. If database is changed, users must be reloaded
    users: OtherUser[]              // list of users in current database
    status: UserManagementStatus      // status of users list
    error?: string                  // error message if status is error
}


const initialState: UserManagementState = {
    users: [],
    status: 'invalid'
}

export const loadUsers = createAsyncThunk(
    'userManagement/load',
    async (payload: string, thunkApi) => {
        const state = thunkApi.getState() as RootState        
        // payload is db name
        const users = await getFromProfitCentral('/?e=user&m=getusers&id=' + encodeURIComponent(payload))
        return users.list
    }
)

export const addUser = createAsyncThunk(
    'userManagement/add',
    async (payload: string, thunkApi) => {
        // payload is user name
        const state = thunkApi.getState() as RootState
        const users = await getFromProfitCentral('/?e=user&m=sharedb'
            + '&d=' + encodeURIComponent(state.userManagement.db!)
            + '&u=' + encodeURIComponent(payload)
            )
        return users.list
    }
)

export const removeUser = createAsyncThunk(
    'userManagement/remove',
    async (payload: string, thunkApi) => {
        // payload is user name
        const state = thunkApi.getState() as RootState
        const users = await getFromProfitCentral('/?e=user&m=remove_user_from_db'
            + '&d=' + encodeURIComponent(state.userManagement.db!)
            + '&u=' + encodeURIComponent(payload)
            )
        return users.list
    }
)

export const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        resetUsers: (state, _) => {
            console.log('reset userManagement')
            state.status = 'invalid'
        },
        resetUsersError: (state, _) => {
            state.status = 'idle'
            state.error = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUsers.pending, (state, action) => {
                console.log('loadUsers pending', action)
                const db = action.meta.arg
                state.db = db
                state.status = 'loading'
            })
            .addCase(loadUsers.fulfilled, (state, action) => {
                console.log('loadUsers fulfilled', action)
                state.users = action.payload
                state.status = 'idle'
            })
            .addCase(loadUsers.rejected, (state, action) => {
                console.log('loadUsers rejected', action)
                state.status = 'error'
                state.error = action.error.message
                // TODO: empty users list?
            })

            .addCase(addUser.pending, (state, action) => {
                console.log('addUser pending', action)
                state.status = 'adding'
                state.users = [...state.users, { 
                    id: 0, uid: action.meta.arg, name: action.meta.arg, status: 'creating' 
                }]
            })
            .addCase(addUser.fulfilled, (state, action) => {
                console.log('addUser fulfilled', action)
                state.status = 'idle'
                state.users = action.payload //state.users.map(u => u.email === action.meta.arg ? { ...u, status: 'normal' } : u)
            })
            .addCase(addUser.rejected, (state, action) => {
                console.log('addUser rejected', action)
                state.status = 'error'
                state.error = action.error.message
                // state.users = state.users.filter(u => u.email !== action.meta.arg)
            })

            .addCase(removeUser.pending, (state, action) => {
                console.log('removeUser pending', action)
                state.status = 'deleting'
                state.users = state.users.map(u => u.uid === action.meta.arg ? { ...u, status: 'deleting' } : u)
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                console.log('removeUser fulfilled', action)
                state.status = 'idle'
                state.users = action.payload
            })
            .addCase(removeUser.rejected, (state, action) => {
                console.log('removeUser rejected', action)
                state.status = 'error'
                state.error = action.error.message
            })
    }
});

export const { resetUsers, resetUsersError } = userManagementSlice.actions;

export const selectUsersForDb = (db: string): UserManagementState =>
    useSelector((state: RootState) => state.userManagement.db === db && state.userManagement || initialState)

export const selectUserManagementStatus = (db: string): UserManagementStatus =>
    useSelector((state: RootState) => state.userManagement.db === db ? state.userManagement.status : 'invalid')

export default userManagementSlice.reducer;
