import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ASC, DESC, SETUP_PREFIX_GRID, SETUP_SUFFIX_SORTDIR, SETUP_SUFFIX_SORTED } from '../../app/const';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { UpdateFieldDataPayload } from '../../app/types/DataConnector';


export interface KVP {
    k: string
    v: any
}

interface LocalSetupState {
    [key: string]: string | LocalSetupState
}

export const setLocalSetupValue = createAsyncThunk(
    'localSetup/setLocalSetupValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        // const state = thunkApi.getState() as RootState
        // const parts = docPath.split('/')
        return { k: field, v: val }
    }
)

/**
 * Set a value in local setup
 * Used for grid setup, filter setup, etc.
 * @param docPath - path to the document
 * @param field - field name in the document designated by docPath
 * @param val - value to set
 */
export const setLocalSetupSubValue = createAsyncThunk(
    'localSetup/setLocalSetupSubValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        // const parts = docPath.split('/')
        return { d: docPath, k: field, v: val }
    }
)

export const localSetupSlice = createSlice({
    name: 'localSetup',
    initialState: {} as LocalSetupState,
    reducers: {
        setLocalSetupItem: (state, action: PayloadAction<KVP>) => {
            // console.log('setLocalSetupItem: ', action.payload)
            const { k, v } = action.payload

            const [prefix, value, suffix] = k.split('.')

            // Special handling for sort order
            if (prefix === SETUP_PREFIX_GRID && suffix === SETUP_SUFFIX_SORTED) {
                const dirStateName = SETUP_PREFIX_GRID + '.' + value + '.' + SETUP_SUFFIX_SORTDIR
                if (state[k] === v) {
                    const sortDir = state[dirStateName]
                    // console.log('same column, dir: ', sortDir)
                    if (sortDir === DESC) {
                        delete (state[dirStateName])
                        delete (state[k])
                    }
                    if (!sortDir || sortDir === ASC)
                        state[dirStateName] = DESC
                    return
                } else
                    delete (state[dirStateName])


            }

            state[k] = v
            // console.log('state: ', state[k])
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLocalSetupValue.pending, (state, action) => {
                console.log('pending save setup', action)
            })
            .addCase(setLocalSetupValue.fulfilled, (state, action) => {
                if (action.payload!.v === undefined)
                    delete state[action.payload!.k]
                else
                    state[action.payload!.k] = action.payload!.v
                console.log('fulfilled save local setup', action.payload, ' ==> ', state[action.payload!.k])
            })
            .addCase(setLocalSetupValue.rejected, (state, action) => {
                console.warn(state, action);
            })

            .addCase(setLocalSetupSubValue.pending, (state, action) => {
                console.log('pending save setup subvalue', action)
            })
            .addCase(setLocalSetupSubValue.fulfilled, (state, action) => {
                // retrieve value from state
                const {d, k, v} = action.payload!;
                let value: LocalSetupState;
                try {
                    if(typeof state[d] === 'string')
                        value = {};
                    else
                        value = (state[d]) as LocalSetupState || {};
                } catch (e) {
                    console.warn('error in setLocalSetupSubValue', e);
                    value = {};
                }
                if (v === undefined)
                    delete value[k];
                else
                    value[k] = v;

                state[d] = value;
                    
                console.log('fulfilled save local setup subvalue', action.payload, ' ==> ', state[d]);
            })
            .addCase(setLocalSetupSubValue.rejected, (state, action) => {
                console.warn(state, action);
            })

    },
});

export const { setLocalSetupItem } = localSetupSlice.actions;

export const selectLocalSetupValue = (key: string, def: string | undefined = undefined) => useSelector((state: RootState) => state.localSetup[key] || def);
export const selectLocalSetupSubValue = (path: string, key: string, def: string | undefined = undefined) => 
        useSelector((state: RootState) => {
            
            return state.localSetup[path][key] || def
        });

export const selectLocalSetup = () => useSelector((state: RootState) => state.localSetup);

export default localSetupSlice.reducer;