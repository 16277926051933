import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApplicationUnit } from '../../profit/regs'

export interface SelectorDialogData {
    docPath: string         // path to document 
    field: string           // field of document
    title: string           // title of dialog
    selectorReg: ApplicationUnit     // registry to select from
    sharedSetup: any        // shared setup to use
    defaultValue?: number | string
}

export interface SelectorsDialogsState {
    stack: SelectorDialogData[]
}

const initialState: SelectorsDialogsState = {
    stack: []
}

export const selectorDialogsSlice = createSlice({
    name: 'selectorDialogs',
    initialState,
    reducers: {
        resetDialogs: (state) => {
            state.stack = []
        },
        pushDialog: (state, action: PayloadAction<SelectorDialogData>) => {
            console.log('pushing', action.payload)
            state.stack.push(action.payload)
        },
        popDialog: (state) => {
            console.log('poping', state)
            state.stack.pop()
        }
    }
});

export const { resetDialogs, pushDialog, popDialog } = selectorDialogsSlice.actions;

export const selectSelectorDialogsTop = (state: RootState) => state.selectorDialogs.stack[state.selectorDialogs.stack.length - 1];
export const selectSelectorDialogsCount = (state: RootState) => state.selectorDialogs.stack.length;

export default selectorDialogsSlice.reducer;
