import React from "react";
import { Navigate } from "react-router";
import { Outlet } from 'react-router-dom'
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../redux/auth/authSlice";
import { APP_URLS } from "../configs/appConfig";

const PublicOnlyRoutes = () => {
    const user = useAppSelector(selectUser);
    return user ? <Navigate to={APP_URLS.FRONTPAGE}/> : <Outlet/>
}

export default PublicOnlyRoutes;