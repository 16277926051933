import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { get } from '../../profit/api';
import { useSelector } from 'react-redux';
import { splitDocPath } from '../docs/utils';
// import { splitDocPath } from '../docs/utils';
// import { getRegDescriptor } from '../../profit';

export type PrivilegesStatus = 'invalid' | 'idle' | 'loading' | 'error';

// [{"id":12000,"name":"articles","c":true,"r":true,"u":true,"d":true,"e":true}
export interface ItemPrivileges {
    id?: number,
    name: string,
    c?: boolean,
    r?: boolean,
    u?: boolean,
    d?: boolean,
    e?: boolean
};

export interface PrivilegesArray {
    [key: string]: ItemPrivileges
};

export interface Privileges {
    privileges: PrivilegesArray,
    status: PrivilegesStatus,
    error?: string
};

export interface PrivilegesState {
    [key: string]: Privileges
};

const initialState: PrivilegesState = {};

export const loadPrivileges = createAsyncThunk(
    'privileges/load',
    async (payload: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const response = await get(state, payload + '/my_rights');

        const ret : PrivilegesArray = {};
        response.data.forEach((item: ItemPrivileges) => {
            ret[item.name] = item;
        });

        // TODO remove after implemented
        // ret['own_bank_accounts'] = { name: 'own_bank_accounts', c: true, r: true, u: true, d: true, e: true, id: 165000 };

        return ret;
    }
)

export const privilegesSlice = createSlice({
    name: 'privileges',
    initialState,
    reducers: {
        resetAll: (state, action) => {
            state = initialState;
        },
        reset: (state, action: PayloadAction<string>) => {
            delete state[action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadPrivileges.pending, (state, action) => {
                state[action.meta.arg] = { status: 'loading', privileges: {} };
            })
            .addCase(loadPrivileges.fulfilled, (state, action) => {
                // const docPath = action.meta.arg
                state[action.meta.arg] = { status: 'idle', privileges: action.payload };
            })
            .addCase(loadPrivileges.rejected, (state, action) => {
                const db = action.meta.arg;
                state[db] = { status: 'error', privileges: {} };
                state[db].error = action.error.message;
            })
    }
});

export const { resetAll, reset } = privilegesSlice.actions;

export const selectPrivilegesForDocPath = (docPath: string) => {
    const { dbName, regName, id } = splitDocPath(docPath);
    return selectPrivilegesForUnit(dbName, regName as string);
}

export const selectPrivilegesForUnit = (db: string, unit: string) : ItemPrivileges => {
    if(db === '')
        return {name: ''};
    if(unit === '')
        return {name: ''};
    return useSelector((state: RootState) => state.privileges[db]?.privileges[unit]);
}

export const selectPrivileges = (db: string) =>
    useSelector((state: RootState) => state.privileges[db]);

export const selectPrivilegesStatus = (db: string): PrivilegesStatus => {
    if(db === '')
        return 'error';
    return useSelector((state: RootState) => state.privileges[db]?.status || 'invalid');
}

export const selectPrivilegesError = (db: string) => 
    useSelector((state: RootState) => state.privileges[db]?.error);

export default privilegesSlice.reducer;
