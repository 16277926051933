import React from "react";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import LoginAppBar from "../components/login/LoginAppBar";
import ErrorBox from "../components/ErrorBox";

export default function NoMatch() {
    const navigate = useNavigate();
    const location = useLocation();
    return <>
        <LoginAppBar title='page_not_found' />
        <Container sx={{ pt: 10 }}>
            <ErrorBox
                title='page_not_found'
                message='page_not_found_message'
                messageValues={{ path: location.pathname }}
                actions={[
                    {
                        label: 'btn_go_home',
                        action: () => navigate('/')
                    }
                ]}
            />
        </Container>
    </>
}
